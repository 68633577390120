import { render, staticRenderFns } from "./PackagingForm.vue?vue&type=template&id=18344852&scoped=true"
import script from "./PackagingForm.vue?vue&type=script&lang=ts"
export * from "./PackagingForm.vue?vue&type=script&lang=ts"
import style0 from "./PackagingForm.vue?vue&type=style&index=0&id=18344852&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18344852",
  null
  
)

/* custom blocks */
import block0 from "@/locales/packagings/packagings_form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FPackagingForm.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/popup.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FPackagingForm.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/shipment_catalogues/shipment_catalogues.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FPackagingForm.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/locales/packagings/commodity_form.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FPackagingForm.vue&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@/locales/packagings/packaging_configuration.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fpackagings%2FPackagingForm.vue&external"
if (typeof block4 === 'function') block4(component)

export default component.exports