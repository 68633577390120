import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer'
import { AbstractModel } from '@/models/abstract-model'
import { PackagingParcelReference } from '@/models/packaging-parcel-reference'
import Vue from 'vue'
import moment from 'moment'
import {
  CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE,
  CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE,
  PM_PACKAGING_CATEGORY, PM_PRODUCT_CATEGORY, SHIPMENT_TYPE
} from '@/models/shipment'
import { PackagingCommodity } from './packaging-commodity'

export interface ISelectData {
  text: string,
  value: string | number | undefined,
  selected?: boolean
}

export const MEASUREMENT_UNIT_LIST: ISelectData[] = [
  { value: 'i', text: 'in' },
  { value: 'm', text: 'sm' }
]

export const MEASUREMENT_WEIGHT_UNIT_LIST: ISelectData[] = [
  { value: 'i', text: 'lbs' },
  { value: 'm', text: 'kg' }
]

export const NORMALISED_WEIGHT_UNIT_LIST: ISelectData[] = [
  { value: 'lbs', text: 'lbs' },
  { value: 'kg', text: 'kg' }
]

export enum PACKAGING_TYPE {
  PACKAGING = 1,
  PARCEL_TEMPLATE = 2
}

export interface ICategory {
  type?: string,
  key?: number | null,
  code?: number | string,
  name: string
}

@Exclude()
export class Range extends AbstractModel {
  @Expose({ name: 'minimum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minimum: number | undefined | null = undefined

  @Expose({ name: 'maximum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maximum: number | undefined | null = undefined

  @Expose({ name: 'weight_unit' })
  @Transform(value => value || 'kg', { toClassOnly: true })
  public weightUnit: string = 'kg'

  @Expose({ name: 'configured', toClassOnly: true })
  @Transform((_value, obj) => !!obj.minimum || !!obj.maximum || false, { toClassOnly: true })
  public configured: boolean = false

  public wasValidated: boolean = false
}

@Exclude()
export class DimensionalRange extends AbstractModel {
  @Expose({ name: 'minimum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minimum: number | undefined | null = undefined

  @Expose({ name: 'maximum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maximum: number | undefined | null = undefined

  @Expose({ name: 'dimension_unit' })
  @Transform(value => value || 'kg', { toClassOnly: true })
  public dimensionUnit: string = 'kg'

  @Expose({ name: 'configured', toClassOnly: true })
  @Transform((_value, obj) => !!obj.minimum || !!obj.maximum || false, { toClassOnly: true })
  public configured: boolean = false

  public wasValidated: boolean = false
}

@Exclude()
export class Items extends AbstractModel {
  @Expose({ name: 'minimum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public minimum: number | undefined | null = undefined

  @Expose({ name: 'maximum' })
  @Transform(value => value || undefined, { toClassOnly: true })
  public maximum: number | undefined | null = undefined

  @Expose({ name: 'configured', toClassOnly: true })
  @Transform((_value, obj) => !!obj.minimum || !!obj.maximum || false, { toClassOnly: true })
  public configured: boolean = false

  public wasValidated: boolean = false
}

@Exclude()
export class Ecommerce {
  @Expose({ name: 'weight' })
  @Type(() => Range)
  @Transform(value => value ?? plainToClass(Range, {}), { toClassOnly: true })
  public weight: Range = plainToClass(Range, {})

  @Expose({ name: 'dimensional_weight' })
  @Type(() => DimensionalRange)
  @Transform(value => value ?? plainToClass(DimensionalRange, {}), { toClassOnly: true })
  public dimensionalWeight: DimensionalRange = plainToClass(DimensionalRange, {})

  @Expose({ name: 'items' })
  @Type(() => Items)
  @Transform(value => value ?? plainToClass(Items, {}), { toClassOnly: true })
  public items: Items = plainToClass(Items, {})

  @Expose({ name: 'categories' })
  @Transform(value => value ?? [], { toClassOnly: true })
  public categories: ICategory[] = []

  @Expose({ name: 'categories_configured', toClassOnly: true })
  @Transform((_value, obj) => (obj.categories && obj.categories.length > 0) || false, { toClassOnly: true })
  public categoriesConfigured: boolean = false
}

@Exclude()
export class Configurations {
  @Expose({ name: 'ecommerce' })
  @Type(() => Ecommerce)
  @Transform(value => value ?? [], { toClassOnly: true })
  public ecommerce: Ecommerce[] = []
}

@Exclude()
export class Dates {
  @Expose({ name: 'created_at', toClassOnly: true })
  @Transform(value => {
    if (value) {
      return `${moment(Date.parse(value)).format(Vue.moment.localeData().longDateFormat('L'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public createdAt: string = ''

  @Expose({ name: 'updated_at', toClassOnly: true })
  @Transform(value => {
    if (value) {
      return `${moment(Date.parse(value)).format(Vue.moment.localeData().longDateFormat('L'))} `
    } else {
      return ''
    }
  }, { toClassOnly: true })
  public updatedAt: string = ''
}

@Exclude()
export class PackagingOwner {
  @Expose({ name: 'user' })
  @Transform(value => value ?? { id: null }, { toClassOnly: true })
  public user: { id?: number | null, firstname?: string, lastname?: string, email?: string } = { id: null }

  @Expose({ name: 'company' })
  @Transform(value => value ?? { id: null }, { toClassOnly: true })
  public company: { id?: number | null, company?: string } = { id: null }
}

@Exclude()
export class PackagingContent {
  @Expose({ name: 'content_description' })
  @Transform(value => { return (!value) ? '' : value }, { toClassOnly: true })
  public contentDescription: string = ''
}


@Exclude()
export class PackagingMeta {
  @Expose({ name: 'packaging_type' })
  @Transform(value => {
    if (value === 'parcel_template') {
      return PACKAGING_TYPE.PARCEL_TEMPLATE
    } else if (value === 'packaging') {
      return PACKAGING_TYPE.PACKAGING
    } else {
      return (!value) ? PACKAGING_TYPE.PACKAGING : value
    }
  }, { toClassOnly: true })
  public packagingType: number = PACKAGING_TYPE.PACKAGING

  @Expose({ name: 'shipment_type' })
  public shipmentType: number | undefined | null = null

  @Expose({ name: 'content' })
  @Type(() => PackagingContent)
  @Transform(value => value ?? plainToClass(PackagingContent, {}), { toClassOnly: true })
  public content: PackagingContent = plainToClass(PackagingContent, {})

  @Expose({ name: 'insurance_amount' })
  @Transform(value => { return (value === null) ? '' : value }, { toClassOnly: true })
  public insuranceAmount: number | string | null = ''

  @Expose({ name: 'currency' })
  @Transform(value => value || 'MXN', { toClassOnly: true })
  public currency: string | null = null

  @Expose({ name: 'items' })
  @Type(() => PackagingCommodity)
  @Transform(value => value ?? [], { toClassOnly: true })
  public packagingCommodities: PackagingCommodity[] = []

  @Expose({ name: 'references' })
  @Type(() => PackagingParcelReference)
  @Transform(value => value ?? [], { toClassOnly: true })
  public packagingParcelReferences: PackagingParcelReference[] = []

  @Expose({ name: 'owners' })
  @Type(() => PackagingOwner)
  @Transform(value => value ?? plainToClass(PackagingOwner, { user: { id: null }, company: {} }), { toClassOnly: true })
  public owner: PackagingOwner = plainToClass(PackagingOwner, { user: {}, company: { id: null } })

  @Expose({ name: 'categories' })
  @Transform(value => value ?? [
    { type: 'mx_sat_packaging', key: PM_PACKAGING_CATEGORY, code: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PACKAGING_CATEGORY_DEFAULT_VALUE.text },
    { type: 'mx_sat_product', key: PM_PRODUCT_CATEGORY, code: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.value, name: CATALOGUES_PRODUCT_CATEGORY_DEFAULT_VALUE.text }
  ], { toClassOnly: true })
  public categories: ICategory[] = []

  @Expose({ name: 'dates' })
  @Type(() => Dates)
  @Transform(value => value ?? plainToClass(Dates, {}), { toClassOnly: true })
  public dates: Dates = plainToClass(Dates, {})

  public get packagingCategory () {
    return this.categories.find((category: ICategory) => category.type === 'mx_sat_packaging' )
  }

  public get productCategory () {
    return this.categories.find((category: ICategory) => category.type === 'mx_sat_product' )
  }
}

@Exclude()
export class Packaging extends AbstractModel {
  static get modelName () {
    return 'Packaging'
  }

  @Expose({ name: 'packaging_type' })
  @Transform(value => {
    console.log(value)
    if (value === 'parcel_template') {
      return PACKAGING_TYPE.PARCEL_TEMPLATE
    } else if (value === 'packaging') {
      return PACKAGING_TYPE.PACKAGING
    } else {
      return (!value) ? PACKAGING_TYPE.PACKAGING : value
    }
  }, { toClassOnly: true })
  public packagingType: number = PACKAGING_TYPE.PACKAGING

  @Expose({ name: 'name' })
  @Transform(value => value || '', { toClassOnly: true })
  public name: string = ''

  @Expose({ name: 'quantity' })
  @Transform(value => value || 1, { toClassOnly: true })
  public quantity: number | undefined | null = 1

  @Expose({ name: 'length' })
  @Transform(value => value || 0, { toClassOnly: true })
  public length: number | undefined | null = undefined

  @Expose({ name: 'width' })
  @Transform(value => value || 0, { toClassOnly: true })
  public width: number | undefined | null = undefined

  @Expose({ name: 'height' })
  @Transform(value => value || 0, { toClassOnly: true })
  public height: number | undefined | null = undefined

  @Expose({ name: 'weight' })
  @Transform(value => value || 0, { toClassOnly: true })
  public weight: number | undefined | null = undefined

  @Expose({ name: 'dimension_unit' })
  @Transform(value => value || 'm', { toClassOnly: true })
  public measurementUnit: string = 'm'

  @Expose({ name: 'weight_unit' })
  @Transform(value => value || 'm', { toClassOnly: true })
  public weightUnit: string = 'm'

  @Expose({ name: 'meta' })
  @Type(() => PackagingMeta)
  @Transform(value => value ?? plainToClass(PackagingMeta, {}), { toClassOnly: true })
  public packagingMeta: PackagingMeta = plainToClass(PackagingMeta, {})

  @Expose({ name: 'configurations' })
  @Type(() => Configurations)
  @Transform(value => value ?? plainToClass(Configurations, {}), { toClassOnly: true })
  public configurations: Configurations = plainToClass(Configurations, {})

  @Expose({ name: 'has_dangerous_materials' })
  public hasDangerousMaterials: boolean | undefined = false

  @Expose({ name: 'dangerous_material_product_category' })
  public dangerousMaterialProductCategory: string | undefined

  @Expose({ name: 'dangerous_material_packaging_category' })
  public dangerousMaterialPackagingCategory: string | undefined

  public get isPackaging () {
    return this.packagingMeta.packagingType === PACKAGING_TYPE.PACKAGING || this.packagingType === PACKAGING_TYPE.PACKAGING
  }

  public get isParcelTemplate () {
    return this.packagingMeta.packagingType === PACKAGING_TYPE.PARCEL_TEMPLATE || this.packagingType === PACKAGING_TYPE.PARCEL_TEMPLATE
  }

  public get isDocument () {
    return this.packagingMeta.shipmentType === SHIPMENT_TYPE.DOCUMENT
  }

  public get isParcel () {
    return this.packagingMeta.shipmentType === SHIPMENT_TYPE.PACKAGE
  }

  public get commodityTotals () {
    let totals = {
      weight: 0,
      value: 0
    }
    this.packagingMeta.packagingCommodities.map((commodity: PackagingCommodity) => totals.weight += (commodity.totalWeight || 0))
    this.packagingMeta.packagingCommodities.map((commodity: PackagingCommodity) => totals.value += (commodity.totalValue || 0))
    return totals
  }

  public assignTo: string = 'user'
}
